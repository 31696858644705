import React from "react"
import { Container, Col, Row } from "reactstrap"

import "../../styles/examplerequest.css"

import ScrollAnimation from "react-animate-on-scroll"

import identify from "../../images/landing/remodela/identity.svg"
import id_card from "../../images/landing/remodela/id_card.svg"
import direction from "../../images/landing/remodela/direction.svg"
import bill from "../../images/landing/remodela/bill.svg"

const request = [
  {
    icon: identify,
    text: "23 a 45 años de edad.",
  },
  {
    icon: id_card,
    text: "Identificación oficial de titular y aval.",
  },
  {
    icon: direction,
    text: "Comprobante de domicilio de titular y aval.",
  },
  {
    icon: bill,
    text:
      "Factura original de algún bien mueble a nombre titular, que doble el valor del monto financiado.",
  },
]

function ExampleRequest() {
  return (
    <>
      <Container fluid className="py-md-0 py-5 ">
        <Row className="  align-items-center bg-white ">
          <Col sm={12} md={6} className="">
            <Row className="flex-row ">
              <Col md={11} sm={11}>
                <h2 className="ml-md-4 pl-md-1 pt-md-3 font-weight-bold text-sm-left text-center ">
                  Requisitos
                </h2>
              </Col>
            </Row>
            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
              {request.map(({ icon, text }, i) => (
                <Row className="mt-4  margin-requ">
                  <Col
                    md={1}
                    sm={1}
                    className="aliging d-flexd-flex justify-content-md-start justify-content-center"
                  >
                    <img src={icon} alt="" className="icon_requ" />
                  </Col>
                  <Col md={11} sm={11}>
                    <h5 className="text_requ aliging">{text}</h5>
                  </Col>
                </Row>
              ))}
            </ScrollAnimation>
            <a
              className="d-flex flex-direction-column  ml-md-5 pl-md-2 justify-content-md-start justify-content-sm-center "
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1tBD3Qk6g3rkJ_FLQF0fg80vVt9fG73jY/view"
            >
              <h5 className="text-md-start text-center text-orange mt-5 font-weight-bold mb-5 ">
                <u>Consulta Costo Anual Total (CAT) aquí.</u>
              </h5>
            </a>
          </Col>
          <Col className="profit2bg d-none d-sm-block" sm={12} md={6}></Col>
        </Row>
      </Container>
    </>
  )
}
export default ExampleRequest
