import React from "react"
import { Container, Col, Row } from "reactstrap"

import "../../styles/profitStep.css"

import ScrollAnimation from "react-animate-on-scroll"

import money from "../../images/landing/remodela/money.svg"
import autorenew from "../../images/landing/remodela/autorenew.svg"
import payment from "../../images/landing/remodela/payment.svg"
import clock from "../../images/landing/remodela/clock.svg"
import calendar_check from "../../images/landing/remodela/calendar_check.svg"
import P_product from "../../images/landing/remodela/P_product.svg"
import check from "../../images/landing/remodela/check.svg"
import send from "../../images/landing/remodela/send.svg"

const profitAndStep = [
  {
    icon: money,
    text: "Sin comisiones por apertura y administración.",
  },
  {
    icon: autorenew,
    text: "Tienes la posibilidad de obtener un préstamo adicional.",
  },
  {
    icon: payment,
    text: "Puedes liquidar tu financiamiento en el plazo seleccionado o antes.",
  },
  {
    icon: clock,
    text: "Pagos semanales, catorcenales o mensuales.",
  },
  {
    icon: calendar_check,
    text: "Plazos de 16 semanas ó 6, 9 ó 12 meses.",
  },
  {
    icon: P_product,
    text: "Cotiza los productos y sevicios que necesitas en nuestras tiendas.",
  },
  {
    icon: check,
    text: "Revisa y aprueba la opción de financiamiento que más te convenga.",
  },
  {
    icon: send,
    text: "Alfa Ferre enviará los productos a tu domicilio.",
  },
  {
    icon: payment,
    text: "Paga el monto correspondiente en el plazo seleccionado.",
  },
]

function ProfitStep() {
  return (
    <>
      <Container className="bg-white" fluid>
        <Row className="mt-3 align-items-center">
          <Col sm={12} md={6} className="">
            <Row className="flex-row ">
              <Col md={11} sm={11}>
                <h2 className="ml-md-4 pl-1 font-weight-bold text-sm-left text-center ">
                  Beneficios
                </h2>
              </Col>
            </Row>
            <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutLeft">
              {profitAndStep.slice(0, 5).map(({ icon, text }, i) => (
                <Row className="mt-4 margin-requ">
                  <Col
                    md={1}
                    sm={1}
                    className="aliging d-flex justify-content-md-start justify-content-center "
                  >
                    <img src={icon} alt="" className="icon_requ" />
                  </Col>
                  <Col md={11} sm={11}>
                    <h5 className="text_requ aliging">{text}</h5>
                  </Col>
                </Row>
              ))}
            </ScrollAnimation>
          </Col>

          <Col className="profitbg d-none d-sm-block" sm={12} md={6}></Col>
        </Row>

        <Row className=" align-items-center py-md-0 py-5">
          <Col className="profit1bg d-none d-sm-block" sm={12} md={6}></Col>
          <Col sm={12} md={6}>
            <Row className="flex-row ">
              <Col md={11} sm={11}>
                <h2 className="ml-md-4 font-weight-bold text-md-left text-center">
                  Solo cumple los siguientes pasos:
                </h2>
              </Col>
            </Row>
            <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight">
              {profitAndStep.slice(5).map(({ icon, text }, i) => (
                <Row className="mt-4  margin-requ">
                  <Col
                    md={1}
                    sm={1}
                    className="aliging  d-flex justify-content-md-start justify-content-center"
                  >
                    <img src={icon} alt="" className="icon_requ" />
                  </Col>
                  <Col md={11} sm={11}>
                    <h5 className="text_requ aliging">{text}</h5>
                  </Col>
                </Row>
              ))}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProfitStep
