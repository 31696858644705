import React from "react"
import { Container, Row } from "reactstrap"
import { CalculatorFinancing } from "../elements"
import "../../styles/home.css"

function CalculatorFinancingContent() {
  return (
    <>
      <Container className="bg-secondary" fluid>
        <Container fluid className="">
          <Row className="d-flex flex-direction-column justify-content-center pt-md-3 py-3">
            <h1 className=" text-white text-center sizeTitle">
              Simula tu financiamiento
            </h1>
          </Row>
        </Container>
      </Container>
      <CalculatorFinancing />
    </>
  )
}

export default CalculatorFinancingContent
