import React from "react"
import { Container, Col, Row } from "reactstrap"

import "../../styles/home.css"

function Home() {
  return (
    <Container
      fluid
      className="bg_home d-flex flex-column justify-content-center "
    >
      <Row className="d-flex align-items-center ">
        <Col>
          {" "}
          <h1 className="font-weight-bold text-white text-center text-md-left ">
            <span className="color_secondary_text">Transformando</span> nuestra
            realidad <br />
            en espacios{" "}
            <span className="color_secondary_text">sustentables</span>
            ,<br />
            <span className="color_secondary_text">seguros e inclusivos.</span>
          </h1>
          {/* <Button> Visita nuestra tienda </Button> */}
        </Col>
      </Row>
    </Container>
  )
}

export default Home
