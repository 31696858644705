import React from "react"
import { Container, Col, Row, Button } from "reactstrap"

import "../../styles/homeR.css"
import ScrollAnimation from "react-animate-on-scroll"

function HomeR() {
  return (
    <>
      <Container
        fluid
        className="bg_img_Home bg-black d-flex flex-column justify-content-center "
      >
        <Row className="justify-content-center align-items-end text-center">
          <Col>
            {" "}
            <h1 className=" color_text_remodela text-center">
              Adapta tu vivienda a tus nuevas necesidades
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 ">
          <Col className="text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
              <Button tag="a" href="#contacto" color="primary" className="">
                {" "}
                Contáctanos
              </Button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5 bg-white">
        <Container>
          <Row>
            <Col>
              {" "}
              <h1 className="px-md-5 px-sm-1  py-2 text-center sizeTitle">
                ¡Deja de postergar la remodelación de tu casa y utiliza esta
                herramienta para hacerlo ya!
              </h1>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default HomeR
