import React from "react"
import { Container, Col, Row, Button } from "reactstrap"

import "../../styles/products.css"

import pisos from "../../images/landing/floors.svg"
import iluminacion from "../../images/landing/illumination.svg"
import pinturas from "../../images/landing/paintings.svg"
import herramientas from "../../images/landing/tools.svg"
import construccion from "../../images/landing/building.svg"
import ferreteria from "../../images/landing/hardware.svg"
import baños from "../../images/landing/bathrooms.svg"
import jardin from "../../images/landing/yard.svg"
import plomeria from "../../images/landing/plumbing.svg"
import seguridad from "../../images/landing/security.svg"
import electrico from "../../images/landing/electric.svg"
import puertas from "../../images/landing/doors.svg"
import bitso from "../../images/landing/bitso.png"

import ScrollAnimation from "react-animate-on-scroll"

const construccionItems = [
  { icon: pisos, title: "Pisos" },
  { icon: iluminacion, title: "Iluminación" },
  { icon: pinturas, title: "Pinturas" },
  { icon: ferreteria, title: "Herramientas" },
  { icon: construccion, title: "Construcción" },
  { icon: herramientas, title: "Ferretería" },
  { icon: puertas, title: "Baños" },
  { icon: electrico, title: "Jardín" },
  { icon: seguridad, title: "Plomería" },
  { icon: plomeria, title: "Seguridad" },
  { icon: jardin, title: "Eléctrico" },
  { icon: baños, title: "Puertas y ventanas" },
]

function Products() {
  return (
    <>
      <Container
        fluid
        className="bg_building px-3 p-md-5 d-flex align-items-center"
      >
        <Row>
          <Col>
            <Row className="d-flex justify-content-center">
              <Col md={4}>
                <h4 className="text-center font-weight-normal ">
                  Te ayudamos en la construcción de tus sueños
                </h4>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center ">
              <Col className="d-flex align-content-center">
                <Row className="mx-2 mx-md-2  my-2 my-md-3">
                  {construccionItems.map(({ title, icon }, i) => (
                    <Col key={i} md={2} xs={6} className="mb-1 mb-md-2">
                      <Row className="align-items-center bg-secondary p-md-1 mr-1 p-1 mr-md-1 content_card_constr">
                        <img
                          src={icon}
                          alt="icono"
                          className="rounded-circle bg-black p-md-1  circle_icon_const"
                        />

                        <Col
                          md={7}
                          xs={6}
                          className="d-flex felx-column align-content-center text-light px-1 padding_text_img_square"
                        >
                          {title}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center m-md-0 mt-4">
              <div>
                <a className="" href="/construye/">
                  <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                    <Button className="btn-primary">Más información</Button>
                  </ScrollAnimation>
                </a>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="bg_18 d-flex flex-column justify-content-center "
      >
        <Row className="d-flex align-items-center py-5 ">
          <Col className="fondo18  py-5" xs={12} md={4}>
            <h4 className=" text-center text-light montos">Recibe hasta</h4>
            <h1 className=" text-center text-light">18%</h1>
          </Col>
          <Col md={8} xs={12} className="pl-md-5">
            <h1 className="text-light text-center pb-md-2">
              Deja que tu dinero crezca con nosotros
            </h1>
            <Row className="d-flex justify-content-center m-md-0 mt-md-3 pl-md-3 ">
              <a className="" href="/invierte/">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                  <Button className="btn-primary mt-5 mt-md-0">
                    Más información
                  </Button>
                </ScrollAnimation>
              </a>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="bg_finan d-flex flex-column justify-content-center "
      >
        <Row className="py-md-5 pt-5 ">
          <Col
            xs={{ size: 10, order: 2 }}
            md={{ size: 5, order: 1 }}
            className="d-flex justify-content-center flex-column"
          >
            <h4 className="text-light d-flex justify-content-center montos1 pl-md-5">
              Montos
            </h4>

            <h3 className="color_secondary_text fondo18 text-left mb-3 custom_padding">
              Desde: <font className=" text-white">$10,000.00</font>
            </h3>

            <h3 className=" color_secondary_text fondo18 text-left mb-md-3 custom_padding">
              Hasta:
              <span className="text-white"> $210,000.00</span>
            </h3>
          </Col>

          <Col xs={{ size: 12, order: 1 }} md={{ size: 7, order: 2 }}>
            <h1 className="text-light text-center mb-md-4">
              Financiamos tus proyectos de remodelación
            </h1>
            <Row className="d-flex justify-content-center mb-md-5">
              <a className="" href="/remodela/">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                  <Button className="btn-primary  mobile_disappear ">
                    Más información
                  </Button>
                </ScrollAnimation>
              </a>
            </Row>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center mb-md-5">
          <a className="" href="/remodela/">
            <Button className="btn-primary mobile_appear ">
              Más información
            </Button>
          </a>
        </Row>
      </Container>
      <Container
        fluid
        className="bg_xrp1  d-flex flex-column justify-content-center "
      >
        <Row className="d-flex align-items-center py-5 px-md-5">
          <Col md={6} className="">
            <h1 className=" text-center">
              Asegura que tus remesas se ocupen en lo que quieres
            </h1>
            <Row className="d-flex justify-content-center mt-4">
              <a className="" href="/cripto/">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                  <Button className="btn-primary  mobile_disappear ">
                    Más información
                  </Button>
                </ScrollAnimation>
              </a>
            </Row>
          </Col>
          <Col className="d-flex justify-content-center py-md-5" md={6}>
            <img src={bitso} alt="bitso" className="img-fluid w-50 " />
          </Col>
        </Row>

        <Row className="d-flex justify-content-center mt-md-0 mb-5">
          <a className="" href="/cripto/">
            <Button className="btn-primary mobile_appear mb-2">
              Más información
            </Button>
          </a>
        </Row>
      </Container>
    </>
  )
}

export default Products
